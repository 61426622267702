import {backendUrl, siteOrigin} from "../../globalConfig";

export const createNewVTU = async (symbol: string, quantity: number, buyPrice: number, sellPrice: number, buyFirst: boolean) => {
    return await fetch(backendUrl+'/vtu/create_new_vtu', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        },
        body: JSON.stringify({
            symbol: symbol,
            quantity: quantity,
            buy_price: buyPrice,
            sell_price: sellPrice,
            buy_first: buyFirst,
            send_telegram_on_finish: true
        })
    })
        .then(r => r.json())
}
