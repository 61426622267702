


import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Slider from '@mui/material/Slider';
import SymbolValueField from "./SymbolValueField/SymbolValueField";
import {getSymbolInfo} from "../../../asyncActions/vtu/getSymbolInfo";
import NewVTUSelectSymbol from "./NewVTUSelectSymbol/NewVTUSelectSymbol";
import {updateUserFreeCoins} from "../../../asyncActions/vtu/updateUserFreeCoins";
import {getUserCoinsVolumeAfterDate} from "../../../asyncActions/vtu/getUserCoinsVolumeAfterDate";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {IOneCoin} from "../../../types/component_types";
import {createNewVTU} from "../../../asyncActions/vtu/createNewVTU";

const NewVTUDialogButton: React.FC = () => {
    const [open, setOpen] = useState(false); // Состояние для контроля открытого состояния диалога


    const [symbol, setSymbol] = useState<string>("");
    const [quantity, setQuantity] = useState<string>('0.0');
    const [buyPrice, setBuyPrice] = useState<string>('0.0');
    const [sellPrice, setSellPrice] = useState<string>('0.0');
    const [buyFirst, setBuyFirst] = useState<boolean>(true);

    const [lotSizeMinQty, setLotSizeMinQty] = useState<number>(0.0);
    const [lotSizeStep, setLotSizeStep] = useState<number>(0.0);
    const [priceTickSize, setPriceTickSize] = useState<number>(0.0);

    const [freeCoins, setFreeCoins] = useState<number>(0.0);
    const [freeUSDT, setFreeUSDT] = useState<number>(0.0);
    const [maxQuantity, setMaxQuantity] = useState<number>(0.0);

    const [value, setValue] = useState<number>(50); // начальное значение

    const accuracy = lotSizeStep.toString().length - lotSizeStep.toString().replace(/0/g, "").length ;

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number); // обновляем значение ползунка
        setQuantity(newValue.toString())
    };

    const handleChangeBuyFirst = () => {
        setBuyFirst(!buyFirst); // обновляем значение ползунка
    };


    const handleClickOpen = () => {
        setOpen(true); // Открыть диалог
    };

    const handleClose = () => {
        setOpen(false); // Закрыть диалог
    };

    const handleBuyClick = async () => {
        // await addNewTeam(userName, email, telegram, itAges, commandName, streamName, projectAbout, store.referer)
        await createNewVTU(symbol, Number(quantity), Number(buyPrice), Number(sellPrice), buyFirst)
        setOpen(false);
        // setOpen(false); // Закрыть диалог
        // toast.success('Вы успешно зарегистрировали команду ' + commandName, {
        //     autoClose: 10000,  // Уведомление закроется через 30 секунд
        //     position: "top-right"
        // });
    }

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const getUserCoinsVolumeAfterDateWithDelay = async (updated_at: string) => {
        await delay(200);  // Ждем 200 миллисекунд
        const coinsVolume = await getUserCoinsVolumeAfterDate(updated_at)
        return coinsVolume;
    };

    useEffect(() => {
        const fetchData = async () => {
            setQuantity('0.0')
            setBuyPrice('0.0')
            setSellPrice('0.0')
            let localSymbol: string = ""
            if (symbol !== undefined){localSymbol = symbol}
            if (localSymbol != ""){
                const updated_at = await updateUserFreeCoins()
                console.log(updated_at.updated_at)

                const data = await getSymbolInfo(localSymbol);
                setLotSizeMinQty(data.lot_size_min_qty)
                setLotSizeStep(data.lot_size_step)
                setPriceTickSize(data.price_tick_size)

                // while ((await getUserCoinsVolumeAfterDateWithDelay(updated_at.updated_at)).length === 0) {
                //     console.log("Массив пустой, ждем 2 секунды...");
                //     await new Promise(resolve => setTimeout(resolve, 20)); // Ждем 2 секунды
                // }
                const coinsVolume: IOneCoin[] = await getUserCoinsVolumeAfterDateWithDelay(updated_at.updated_at)
                console.log(coinsVolume)

                const USDT = coinsVolume.find(item => item.coin === 'USDT');
                if (USDT != undefined){
                    setFreeUSDT(USDT.free)
                    console.log(freeUSDT)
                    if ((buyFirst == true) && (Number(buyPrice) > 0.0)){
                        setMaxQuantity(parseFloat((USDT.free/Number(buyPrice)).toFixed(accuracy)))  //При изменении buyPrice надо пересчитывать
                    }
                }
                const coin = coinsVolume.find(item => item.coin === symbol.replace("USDT", ""));
                if (coin != undefined){
                    setFreeCoins(coin.free)
                    console.log(freeCoins)
                    if ((buyFirst == false) && (Number(sellPrice) > 0.0)){
                        setMaxQuantity(parseFloat((coin.free/Number(sellPrice)).toFixed(accuracy)))  //При изменении buyPrice надо пересчитывать
                    }
                }
            }
        }
        fetchData()
    },[symbol]);

    //При изменении цены покупки/продажи надо пересчитывать максимальное количество
    useEffect(() => {

        if ((buyFirst == true) && (Number(buyPrice) > 0.0)){
            setMaxQuantity(parseFloat((freeUSDT/Number(buyPrice)).toFixed(accuracy)))   //При изменении buyPrice надо пересчитывать
        }
    }, [buyPrice])

    useEffect(() => {
        if ((buyFirst == false) && (Number(sellPrice) > 0.0)){
            setMaxQuantity(parseFloat((freeCoins/Number(sellPrice)).toFixed(accuracy)))  //При изменении buyPrice надо пересчитывать
        }
    }, [sellPrice])

    useEffect(() => {
        if (Number(quantity) > maxQuantity){
            setQuantity(maxQuantity.toString())  //При изменении buyPrice надо пересчитывать
        }
    }, [maxQuantity])

    useEffect(() => {
        setValue(Number(quantity))  //При изменении buyPrice надо пересчитывать
    }, [quantity])

    return(
        <>
            <Button
                variant="contained"    // Использует стиль заполненной кнопки
                size="large"           // Увеличивает размер кнопки
                onClick={handleClickOpen}
                sx={{
                    width: '200px',      // Задаем ширину кнопки
                    height: '60px',      // Задаем высоту кнопки
                    fontSize: '1.2rem',  // Увеличиваем размер шрифта
                    borderRadius: '8px', // Скругляем углы
                }}
            >
                New VTU
            </Button>
            {/*<ToastContainer />*/}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Creating new VTU</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Заполните все поля
                    </DialogContentText>
                    <NewVTUSelectSymbol symbol={symbol} setSymbol={setSymbol} />

                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <Typography>Sell first</Typography>
                        <Switch checked={buyFirst} onChange={handleChangeBuyFirst} />
                        <Typography>Buy first</Typography>
                    </Stack>

                    {buyFirst ?
                        <SymbolValueField symbol={symbol} label={'Buy price'} quantity={buyPrice} step={priceTickSize} setQuantity={setBuyPrice} /> :
                        <SymbolValueField symbol={symbol} label={'Sell price'} quantity={sellPrice} step={priceTickSize} setQuantity={setSellPrice} />
                    }
                    {buyFirst ?
                        <SymbolValueField symbol={symbol} label={'Sell price'} quantity={sellPrice} step={priceTickSize} setQuantity={setSellPrice} /> :
                        <SymbolValueField symbol={symbol} label={'Buy price'} quantity={buyPrice} step={priceTickSize} setQuantity={setBuyPrice} />
                    }

                    <Slider
                        value={value}
                        onChange={handleChange}
                        aria-labelledby="continuous-slider"
                        min={0}  // минимальное значение
                        max={maxQuantity} // максимальное значение
                        step={lotSizeStep}  // шаг изменения
                        valueLabelDisplay="auto" // показываем значение в момент изменения
                        valueLabelFormat={(value) => value} // формат отображения значения
                    />
                    <Typography variant="h6">Значение: {value}</Typography>

                    <SymbolValueField symbol={symbol} label={'Quantity'} quantity={quantity} step={lotSizeStep} setQuantity={setQuantity} />


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleBuyClick} color="primary">
                        BUY
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewVTUDialogButton;